import './App.css';

import React, { Component, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AddressForm } from './components/AddressForm';
import { ThemeProvider } from '@emotion/react';
import { Container, createTheme, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AddressView } from './views/progressiveProfile/address.view';

const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const theme = createTheme();

class PrivatePage extends Component {
    constructor (props) {
        super(props);
        this.state = { data: [] };
    }

    componentDidMount () {
    }

    render () {
        return (
            <div>
                <AddressForm/>
            </div>
        );
    }
}

const AccessToken = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const getToken = async () => {
            const accessToken = await getAccessTokenSilently({
                audience: `https://${domain}/api/v2/`,
                scope: 'read:current_user'
            });
            console.log(accessToken);
            setAccessToken(accessToken);
        };
        getToken();
    });

    if (!accessToken) {
        return null;
    }

    return (
        <PrivatePage accessToken={accessToken}/>
    );
};

const Page = () => {
    const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();

    if (isLoading) {
        return (
            <div>
                Loading ...
            </div>
        );
    }

    if (!isAuthenticated) {
        return (
            <button onClick={() => loginWithRedirect()}>
                Log In
            </button>
        );
    }

    return (
        <>
            <AccessToken/>
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            <button onClick={() => logout({ returnTo: window.location.origin })}>
                Log Out
            </button>
        </>
    );
};

function App () {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Router>
                    <Switch>
                        <Route path="/auth">
                            <Page></Page>
                        </Route>
                        <Route path="/progressive-profile/address">
                            <AddressView />
                        </Route>
                    </Switch>
                </Router>
            </Container>
        </ThemeProvider>
    );
}

export default App;

import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box } from '@mui/material';

export interface FormInputProps {
    name: string;
    control: any;
    label: string;
    setValue?: any;
    required?: boolean;
    fullWidth?: boolean;
    options: any;
    renderOption: any;
}

export const FormAutocomplete = ({ name, control, label, options, renderOption, required = false, fullWidth = true }: FormInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value },
                fieldState: { error }
            }) => (
                <Autocomplete
                    id={name}
                    options={options}
                    autoHighlight
                    renderOption={renderOption}
                    onInputChange={(event, newValue) => {
                        onChange(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            fullWidth={fullWidth}
                            required={required}
                            value={value}
                            error={!!error}
                            variant="standard"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password' // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

import React from 'react';
import jwt from 'jsonwebtoken';
import { AddressForm } from 'components/AddressForm';
const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const redirectSecret = 'redirectSecretgghhhhhghghghhghghghghghghghgg';

const validateIncomingToken = (sessionToken) => {
    let decoded;
    try {
        decoded = jwt.verify(sessionToken, redirectSecret, { algorithms: ['HS256'] });
    } catch (error) {
        console.log(error);
    }
    return decoded;
};

const generateNewToken = (redirectTokenData, state, formData) => {
    const { sub, iss } = redirectTokenData;
    const payload = {
        sub,
        iss,
        state,
        formData
    };

    const token = jwt.sign(
        payload,
        redirectSecret,
        { expiresIn: '60s', algorithm: 'HS256' }
    );
    return token;
};

export const AddressView: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const sessionToken = params.get('token');
    const state = params.get('state');
    const payload = validateIncomingToken(sessionToken);

    const onSubmit = (formData) => {
        console.log(formData);
        const newToken = generateNewToken(payload, state, formData);
        location.href = `https://${domain}/continue?state=${state}&token=${newToken}`;
    };

    return (
        <div>
            <AddressForm onSubmit={onSubmit} />
        </div>

    );
};

import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export interface FormInputProps {
    name: string;
    control: any;
    label: string;
    setValue?: any;
    required?: boolean;
    fullWidth?: boolean;
}

export const FormInputText = ({ name, control, label, required = false, fullWidth = true }: FormInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value },
                fieldState: { error }
            }) => (
                <TextField
                    helperText={error ? error.message : null}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    label={label}
                    required={required}
                    fullWidth={fullWidth}
                    variant="standard"
                />
            )}
        />
    );
};

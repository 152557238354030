import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { FormInputText } from './form/FormInputText';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { countries } from './form/countries';
import { FormAutocomplete } from './form/FormAutocomplete';

interface AddressFormProps {
    onSubmit(data: any): void;
}

const defaultValues = {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: ''
};

const schema = yup.object({
    firstName: yup.string().trim().required('Required field').min(3, 'Value too short'),
    lastName: yup.string().trim().required('Required field').min(3, 'Value too short'),
    address: yup.string().trim().required('Required field').min(3, 'Value too short'),
    city: yup.string().trim().required('Required field').min(3, 'Value too short'),
    state: yup.string().trim(),
    zip: yup.string().trim().required('Required field').min(3, 'Value too short'),
    country: yup.string().trim().required('Required field').min(3, 'Value too short')
}).required();

// https://blog.logrocket.com/using-material-ui-with-react-hook-form/
export const AddressForm: React.FC<AddressFormProps> = ({ onSubmit }) => {
    const methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema)
    });
    const { handleSubmit, control } = methods;

    return (
        <>
            <Typography variant="h6" gutterBottom marginTop={5} color="#819097">
                Primary address
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="firstName" control={control} label="First name" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="lastName" control={control} label="Last name" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormAutocomplete
                        name="country" control={control} label="Country" required
                        options={countries}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.label} ({option.code}) +{option.phone}
                            </Box>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText name="address" control={control} label="Address line" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="city" control={control} label="City" required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="state" control={control} label="State/Province/Region" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormInputText name="zip" control={control} label="Zip / Postal code" required />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        fullWidth
                        style={{
                            color: '#FFFFFF',
                            border: '1px solid #819097',
                            borderRadius: '20px',
                            backgroundColor: '#819097'
                        }}
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
